/* #####---THIS STYLE SHEET USED ONLY FOR PRODUCT RECEIVE FORM---####*/


/* ######----STYLE FOR PRODUCT RECEIVD HEAdER AREA---##### */
.rcvHeader {
    background-color: var(--primary-color);
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    align-items: center;
    grid-column-gap: 18px;

}


/* #######---PRODUCT RECEIVE AREA---##### */
.inputArea {
    padding: 4px;
    background-color: bisque;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

#areaPartion-1 {
    /*PRODUCT RECEIVE INPUT AREA WIDTH SETUP AND GRID DESIGN*/
    width: 82%;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 0px;
}

#areaPartion-1 :nth-child(1) {
    /* SIZING PRODUCT NAME ENTRY AREA USING GRID SPAN */
    grid-column: 1 / span 3;
}

#areaPartion-1 :nth-child(13) {
    grid-column: 6 / span 4;
    justify-self: center;
    align-self: last baseline;
}

#areaPartion-1 label {
    /* CUSTOM INFORMATION AREA LEBEL SIZE-COLOR*/
    align-items: center;
    margin-top: 2px;
}

/* INPUT AREA PARTITION-2 USING GRID  */
#areaPartion-2 {
    /*PRODUCT RECEIVE INFORMATIO AREA WIDTH SETUP AND GRID DESIGN*/
    width: 15%;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    /* align-items: last baseline; */
}

#areaPartion-2 label {
    /* TARGET INFORMATION AREA LEBEL SIZE-ALIGNMENT, FLOTE RIGHT */
    font-weight: bold;
    text-align: end;
    margin-top: 6px;
    padding-right: 4px;
}


/* ########-------PRODUCT RECEIVE GRID DESIGN USING TABLE----###### */
.rcvInfo{
    /* height: 250px; */
    min-height: 120px;
    background-color: var(--container-color);
    border-radius: 4px;
    margin-bottom: 4px;
}

/* ########-------PRODUCT RECEIVE FOOTER AREA----###### */

.rcvFooter{
    background-color: bisque;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
}

/* FOOTER PARTITION-1 CALCULATION */
.footerPartion-1{
    width: 65%;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    grid-column-gap: 12px;
    grid-row-gap: 4px;
    /* border: 1PX SOLID black; */
}

/* GRID CHIELD POSITIONING */
.footerPartion-1 :nth-child(10){
    grid-column: 2 / span 5;
    grid-row: 2;
}
.footerPartion-1 :nth-child(11){
    grid-column: 1;
    grid-row: 3;
}
.footerPartion-1 :nth-child(12){
    grid-column: 2 / span 5;
    grid-row: 3;
}

.footerPartion-1 label {
    /* TARGET  FOOTER INFORMATION AREA LEBEL SIZE-ALIGNMENT, FLOTE RIGHT */
    font-weight: bold;
    text-align: end;
    margin-top: 4px !important;
}

.footerPartion-1 input [tipe=file]{
    align-self: flex-end;
}


/* FOOTER PARTITION-2 OPERATION */
.footerPartion-2{
    width: 33%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    /* border: 1PX SOLID black; */
}



